import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import $ from 'jquery';

// Make jQuery available globally
window.$ = window.jQuery = $;

createApp(App).use(router).mount('#app')
